import * as React from 'react';
import { useState } from 'react';
import { Redirect, RouteComponentProps, useParams, useRouteMatch } from 'react-router-dom';
import { SessionLoadingStep, SessionUploadStep, useCreateSessionIfNotConnect, useSeesionConnect, useUploadImages } from '../hooks/SessionHooks';
import {
  AppContainer,
  Avatar, FrontDoorPanel, Background, PolaPolaAvatar, PolaPolarAvatarTwink, AvatarPanel,
  AvatarMessagePanel, SpeechBubble, AbsolutePanel, BottomPanel, CommonButton, ButtonContainer, HorizentalLine, Header3, CommonLinkButton
} from '../layouts/Layouts';
import Header from '../templates/Header';
import PageUrl from './Pages';

interface ImageUploadLoadingButtonProps {
  percent?: number;
}

function ImageUploadLoadingButton(props: ImageUploadLoadingButtonProps) {
  const { percent } = props;

  const percentValue = percent ?? 0;

  let width =
    percentValue <= 0 ?
      "0px" :
      `calc(${(percentValue / 100) * 60}% + 40px)`;

  return (
    <ButtonContainer>
      <div style={{
        position: "absolute",
        height: "calc(1rem + 40px)",
        width: width,
        marginTop: 4,
        marginLeft: "calc(20% - 20px)",
        background: "rgba(30,30,150,0.3)",
        // transition: "width .1s"
      }}></div>
      <CommonButton
        className="layout--center disable" style={{
          width: "60%"
        }}
      >사진을 보내고 있어요⌛</CommonButton>
    </ButtonContainer>)
}

interface ImageUploadButtonProps {
  uploadFileListAsync?: (fileList: FileList) => void;
  connectionStep?: SessionLoadingStep;
  uploadStep?: SessionUploadStep;
  percent?: number;
}

function ImageUploadButton(props: ImageUploadButtonProps) {
  const { connectionStep, uploadStep, uploadFileListAsync } = props;

  if (connectionStep === SessionLoadingStep.Connect &&
    uploadStep === SessionUploadStep.Ready) {
    return (
      <ButtonContainer>
        <label htmlFor="selectFile">
          <CommonButton
            className="layout--center" style={{
              width: "60%"
            }}
          >사진 보낼래요📷</CommonButton>
        </label>
        <input
          id="selectFile"
          style={{ display: "none" }}
          type="file"
          accept="image/jpg,image/png,image/jpeg,image/gif"
          name="files"
          onChange={async (e) => {
            const fileList = e.target.files;
            if (!fileList) return;

            uploadFileListAsync?.(fileList);

            // const sessionController = new SessionController();

            // try {
            //   for (let idx = 0; idx < fileList.length; idx++) {
            //     await sessionController.postImagesAsync(
            //       sessionKey, fileList[idx], (percent) => {
            //         console.log(percent);
            //       });
            //     setFileUploadCount(idx + 1);
            //   }
            // } catch (error) {
            //   console.log(error);
            // }
          }}
          multiple
        />
      </ButtonContainer>)
  }

  if (connectionStep === SessionLoadingStep.Loading ||
    connectionStep === SessionLoadingStep.PreConnect) {
    return (
      <ButtonContainer>
        <CommonButton
          className="layout--center disable" style={{
            width: "60%"
          }}
        >준비 중이에요 ⌛</CommonButton>
      </ButtonContainer>)
  }

  if (uploadStep === SessionUploadStep.Uploading ||
      uploadStep === SessionUploadStep.Complete) {
    return (<ImageUploadLoadingButton percent={props.percent} />)
  }

  return (<></>)
}

export default function SessionPage(props: RouteComponentProps) {
  const params = (props.match.params as any);
  const sessionKey = params.key;

  const [isClickNextPage, setIsClickNextPage] = useState(false);
  const [fileUploadCount, setFileUploadCount] = useState(0);
  const { connectionStep, userAlias } = useCreateSessionIfNotConnect(sessionKey);
  const { uploadStep, percent, uploadFileListAsync } = useUploadImages(sessionKey);

  if (isClickNextPage) {
    return (
      <Redirect to={PageUrl.MyPlace} />
    )
  }

  let speech = <span></span>;
  if (connectionStep === SessionLoadingStep.Timeout) {
    speech = <span>감사해요! 다음에 또 봬요!</span>
  }
  else if (
    connectionStep === SessionLoadingStep.Connect &&
    uploadStep === SessionUploadStep.Uploading) {
    speech = <span>사진을 보내고 있어요!</span>
  }
  else if (
    connectionStep === SessionLoadingStep.Connect &&
    uploadStep === SessionUploadStep.Complete) {
    speech = <span>사진이 곧 폴라폴라에게서 보일거에요!</span>;
  }
  else if (connectionStep === SessionLoadingStep.Connect) {
    speech = (<><span style={{ fontWeight: "bold" }}>{userAlias}</span><span>님, 반가워요!</span></>);
  }
  else if (connectionStep === SessionLoadingStep.Loading) {
    speech = <span>잠시만요!</span>;
  }
  else if (connectionStep === SessionLoadingStep.PreConnect) {
    speech = <span>잠시만요~!</span>;
  }
  else {
    speech = <span>연결이 끊어졌어요!</span>;
  }

  console.log(`${connectionStep} / ${uploadStep}`)

  return (
    <Background>
      <AppContainer>
        <Header />
        <FrontDoorPanel>
          <AvatarPanel>
            <AbsolutePanel>
              <AvatarMessagePanel>
                <SpeechBubble>
                  {speech}
                </SpeechBubble>
              </AvatarMessagePanel>
              <PolaPolarAvatarTwink />
            </AbsolutePanel>
          </AvatarPanel>
        </FrontDoorPanel>
        <div>
          {/* {status} */}
        </div>
        {/* 커맨드 박스 */}
        <BottomPanel>
          <div style={{
            padding: 10,
            paddingTop: 20,
            paddingBottom: 10
          }}>
            {connectionStep !== SessionLoadingStep.Timeout &&
              <Header3>활용하기</Header3>}
          </div>
          <ImageUploadButton
            connectionStep={connectionStep}
            uploadStep={uploadStep}
            percent={percent}
            uploadFileListAsync={uploadFileListAsync} />
          <HorizentalLine />
          <div style={{
            padding: 10,
            paddingBottom: 10
          }}>
            <Header3>폴라폴라를 더 알고 싶다면?</Header3>
          </div>
          <ButtonContainer>
            <CommonLinkButton className="layout--center secondary" style={{
              width: "60%"
            }} href="https://www.instagram.com/polapolar.official/" target="_blank">
              폴라폴라 인스타그램 🎤
            </CommonLinkButton>
          </ButtonContainer>
          <ButtonContainer>
            <CommonLinkButton className="layout--center secondary" style={{
              width: "60%"
            }} href="https://docs.google.com/spreadsheets/d/1a3DK8onaqK-R5ZKhtzFigNblNYkCtxyFRHKFGIzF9G4/htmlview" target="_blank">
              폴라폴라 입점 매장 🏬
            </CommonLinkButton>
          </ButtonContainer>
          <ButtonContainer>
            <CommonLinkButton className="layout--center secondary" style={{
              width: "60%"
            }} href="https://store.kakao.com/polapolar/category/1ddf3bb7e3924228b50ec5052242ff8c/products" target="_blank">
              폴라폴라 카카오 스토어 🛒
            </CommonLinkButton>
          </ButtonContainer>
          <ButtonContainer>
            <CommonLinkButton className="layout--center secondary" style={{
              width: "60%"
            }} href="https://asked.kr/polapolar" target="_blank">
              📢 무엇이든 물어보세요! 📢
            </CommonLinkButton>
          </ButtonContainer>
        </BottomPanel>
      </AppContainer>
    </Background>
  );
}