import * as React from 'react';
import { useState } from 'react';
import { Redirect, RouteComponentProps, useParams, useRouteMatch } from 'react-router-dom';
import { Avatar } from '../layouts/Layouts';
import PageUrl from './Pages';

export default function ConnectPage(props: RouteComponentProps) {
  const [isClickConnect, setIsClickConnect] = useState(false);

  const params = (props.match.params as any);

  console.log((props.match.params as any)?.key);
  console.log(props.match);
  console.log(props.history);

  if (isClickConnect) {
    return (
      <Redirect to={PageUrl.MyPlace}/>
    )
  }

  return (
    <div>
      ConnectionPage
      <Avatar>
        <div>
          나른한 토끼({params.key})님, 어서와요!
        </div>
        <div>
          (곰)
        </div>
      </Avatar>
      <button onClick={e => {
        setIsClickConnect(true);
      }}>나른한 토끼로 시작</button>
    </div>
  );
}