import * as React from 'react';
import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import Header from '../templates/Header';
import PageUrl from './Pages';

export interface IMyPlacePageProps {
}

export default function MyPlacePage (props: IMyPlacePageProps) {
  const [isClickExit, setIsClickExit] = useState(false);

  if (isClickExit) {
    return (
      <Redirect to={PageUrl.Exit}/>
    )
  }

  return (
    <>
      <Header />
      <div>
        <div>사진 추가하기</div>
        <input
          type="file" name="files"
          onChange={(e) => {
            const files = e.target.files;
            console.log(files);
          }} multiple />
      </div>
      <div>
        <button onClick={e => {
          setIsClickExit(true);
        }}>종료하기</button>
      </div>
    </>
  );
}
