const prefixes = ["귀여운",
  "새침한",
  "활발한",
  "상냥한",
  "구르는",
  "날렵한",
  "행복한", "진지한",
  "신난", "발랄한", "웃긴", "배부른",
  "잉여한", "유명한", "싱싱한", "깨끗한", "맑은", "부유한",
  "착한", "좋은", "똑똑한", "영리한", "사랑스러운", "즐거운",
  "아기", "어른", "화성", "북극", "남극", "아마존", "태평양",
  "방구쟁이", "우주", "고운", "꽃채운", "늘빈", "도란도란", "또바기", "다온",
  "아침", "저녁",];

const nouns = ["원숭이", "고양이", "곰", "강아지", "팽귄", "코끼리", "사자", "호랑이", "사슴",
  "참새", "독수리", "앵무새", "물고기", "잉어", "넙치", "고래",
  "조개",
  "에너지",
  "개미", "매미",
  "나무",
  "가람", "누리", "그린비", "그린내", "글고운", "꽃별", "꽃초롱", "나래", "난이", "너울",
  "서울", "대전", "경기", "부산", "바람", "도담", "다솜", "마루", "가람", "미리내", "구름", "유성", "별", "달", "태양",
  "여우비", "혜성"]

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; //최댓값은 제외, 최솟값은 포함
}

export default class RandomAliasHelper {
  public static create = () => {
    const prefix = prefixes[getRandomInt(0, prefixes.length)]
    const noun = nouns[getRandomInt(0, nouns.length)];
    return `${prefix} ${noun}`;
  }
}