import * as React from 'react';

interface IExitPageProps {
}

export default function ExitPage(props: IExitPageProps) {
  return (
    <div>
      exit
    </div>
  );
}
