import * as React from 'react';

export interface ITimeoutPageProps {
}

export default function TimeoutPage (props: ITimeoutPageProps) {
  return (
    <div>
      
    </div>
  );
}
