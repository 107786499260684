// https://polapolar-planet-dev.azurewebsites.net
import axios from "axios";
import BaseController from "./BaseController";

const hostUri: string = process.env.REACT_APP_POLAR_API_HOST as string;

export interface SessionResponseModel {
  status: {
    connect: boolean;
    timeout: boolean;
  },
  data?: {
    images: {
      id: string,
      origin: string,
      preview: string
    }[],
    user_alias?: string;
  }
}

export interface PostConnectRequestModel {
  sessionKey: string;
  userAlias: string;
}

export default class SessionController extends BaseController {
  constructor() {
    super(hostUri);
  }

  getFromKeyAsync = async (key: string) => {
    const path = "api/v1/instant/session/" + key;
    const result = await axios.get(`${this.hostUrl}/${path}`);
    return result.data as SessionResponseModel;
  }

  postConnectUser = async (model: PostConnectRequestModel): Promise<void> => {
    const params = new URLSearchParams();
    params.append("user_alias", `${model.userAlias}`);

    const path = "api/v1/instant/session/" + model.sessionKey;
    const result = await axios.post(`${this.hostUrl}/${path}?${params}`, null, {
      timeout: 2000
    });
  }

  postImagesAsync = async (key: string, file: Blob, onUploadProgress?: (precent: number) => void) => {
    const formdata = new FormData();
    if (file instanceof File || file instanceof Blob) {
      formdata.append("files", file);
    } else {
      throw Error("not file!");
    }

    const result = await axios.post(
      `${this.hostUrl}/api/v1/instant/session/${key}/images`,
      formdata,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          onUploadProgress && onUploadProgress(percentCompleted);
        }
      }
    );

    return result;
  }
}