import * as React from 'react';
import './Layout.scss';
import styled, { css } from 'styled-components';
import bodyImage from "./../static/media/PP_news_body.png";
import twinkilingImage from "./../static/media/pola_twinkiling_eyes.gif";

interface Props {
  children?: React.ReactNode;
}

function createBlock(blockName: string, modifierName?: string) {
  const func = (props: Props) => {
    if (modifierName) {
      return <div className={`layout--${blockName}__${modifierName}`}>
        {props.children}
      </div>
    }

    return <div className={`layout--${blockName}`}>
      {props.children}
    </div>
  }
  return func;
}

export const Header3 = styled.h3`
  font-size: 22px;
  line-height: 27px;
  letter-spacing: -1px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: -1px;
  font-weight: 700;
`

export const Avatar = createBlock("avatar");

export const FrontDoorPanel = styled.div`
  width: 100%;
  height: 350px;
  position: relative;
`

export const SpeechBubble = createBlock("speech");

export const AbsolutePanel = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const AvatarMessagePanel = styled.div`
  border: 4px solid #4A90E2;
  border-radius: 4px;
  margin: 0 auto;
  width: 300px;
  padding: 20px;
  margin-top: 30px;
  position: relative;
  text-align: center;
`;

export const AvatarPanel = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const CommandPanel = styled.div`

`;

export const Background = styled.div`
  width: 100%;
  height: 100vh;
`

export const AppContainer = styled.div`
  margin: 0 auto;
  max-width: 600px;
  height: 100%;
  position: relative;
`

const PolaPolaAvatarContainer = styled.div`
  width: 100%;
  position: absolute;
  height: 250px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

const ImageDom = styled.img`
  object-fit: contain;
  width: 80%;
  height: 80%;
`

export function PolaPolaAvatar() {
  return <PolaPolaAvatarContainer>
    <div>
      <div style={{
        position: "absolute",
        width: "80%",
        bottom: 0
      }}>
        <ImageDom
          src={bodyImage}
          alt=""
        />
      </div>
      {/* <div style={{
        position: "absolute"
      }}>
        <ImageContainer
          src={armRightImage}
          alt=""
        />
      </div>
      <div style={{
        position: "absolute"
      }}>
        <ImageContainer
          src={armLeftImage}
          alt=""
        />
      </div>
      <div style={{
        position: "absolute"
      }}>
        <ImageContainer
          src={megaphoneImage}
          alt=""
        />
      </div> */}
    </div>
  </PolaPolaAvatarContainer>
}

export function PolaPolarAvatarTwink() {
  return <PolaPolaAvatarContainer>
    <ImageContainer>
      <div style={{
        backgroundImage: `url(${twinkilingImage})`,
        width: "90%",
        height: "100%",
        backgroundSize: "contain",
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat"
      }}>
      </div>
      {/* <ImageDom
        src={twinkilingImage}
        alt=""
      /> */}
    </ImageContainer>
  </PolaPolaAvatarContainer>
}

export const BottomPanel = styled.div`
  background-color: #D8D8D8;
  padding-bottom: 40px;
`;

export const ButtonContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
`

export const CommonButton = styled.div`
  padding: 20px;
  border-radius: 4px;
  min-width: 64px;
  color: #fff;
  background-blend-mode: soft-light,normal;
  background-image: linear-gradient(288deg,#4a4a4a,#ffffff), linear-gradient(to bottom,#0077ff,#0077ff);
  font-family: notosans_cjk_bold;
  text-align: center;
  border: 4px solid rgb(0, 119, 255);

  &.disable {
    color: #000;
    border: 4px solid #888;
    background-image: linear-gradient(288deg, rgb(74, 74, 74), rgb(255, 255, 255)), linear-gradient(rgb(217, 217, 217), rgb(217, 217, 217)) !important;
  }
  &.secondary {
    color: #000;
    border: 0px solid #ca0;
    background-blend-mode: soft-light, normal;
    background-image: linear-gradient(288deg, rgb(74, 74, 74), rgb(255, 255, 255)), linear-gradient(rgb(255, 192, 0), rgb(255, 192, 0));
  }
`

export const CommonLinkButton = styled.a`
  display: block;
  text-decoration: none;
  padding: 20px;
  border-radius: 4px;
  min-width: 64px;
  color: #fff;
  background-blend-mode: soft-light,normal;
  background-image: linear-gradient(288deg,#4a4a4a,#ffffff), linear-gradient(to bottom,#0077ff,#0077ff);
  font-family: notosans_cjk_bold;
  text-align: center;
  border: 4px solid rgb(0, 119, 255);

  &.disable {
    color: #000;
    border: 4px solid #888;
    background-image: linear-gradient(288deg, rgb(74, 74, 74), rgb(255, 255, 255)), linear-gradient(rgb(217, 217, 217), rgb(217, 217, 217)) !important;
  }
  &.secondary {
    color: #000;
    border: 0px solid #ca0;
    background-blend-mode: soft-light, normal;
    background-image: linear-gradient(288deg, rgb(74, 74, 74), rgb(255, 255, 255)), linear-gradient(rgb(255, 192, 0), rgb(255, 192, 0));
  }
`

export const HorizentalLine = styled.hr`
  overflow: visible;
  margin: 50px 20px 30px 20px;
  border: none;
  border-top: medium double #bbb;
  color: #bbb;
  text-align: center;
  &:after {
    content: "🐻‍❄️💙";
    display: inline-block;
    position: relative;
    top: -0.7em;
    font-size: 1.5em;
    padding: 0 0.25em;
    background: #D8D8D8;
  }
`