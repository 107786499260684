import { useState, useEffect } from 'react';
import SessionController from '../controllers/SessionController';
import RandomAliasHelper from '../helpers/RandomAliasHelper';
import Compressor from 'compressorjs';

const compressorBlobAsync = async (inputBlob: Blob): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const compress = new Compressor(inputBlob, {
      maxHeight: 1844,
      minHeight: 1844,
      quality: 0.9,
      convertSize: 1000000, // 1MB 이상의 경우에도 압축
      success: (compressedBlob: any) => {
        resolve(compressedBlob);
      },
      error: (error: any) => {
        reject(error);
      }
    });
  });
}

const retryAsync = async (func: () => Promise<void>, count: number) => {
  for (let idx = 0; idx < count; idx++) {
    try {
      await func();
      return;
    } catch (error) {
      console.error(error);
    }
  }
}

export enum SessionLoadingStep {
  Loading,
  PreConnect,
  Connect,
  Timeout = -1
}

export function useSeesionConnect(key: string) {
  const [connectionStep, setConnectStep] = useState(SessionLoadingStep.Loading);
  const [userAlias, setUserName] = useState("");

  useEffect(() => {
    (async () => {
      try {
        let sessionController = new SessionController();
        const result = await sessionController.getFromKeyAsync(key);

        if (result.status.connect) {
          result.data &&
            result.data.user_alias &&
            setUserName(result.data.user_alias);
          setConnectStep(SessionLoadingStep.Connect);
        } else if (result.status.timeout) {
          setConnectStep(SessionLoadingStep.Timeout);
        } else {
          setConnectStep(SessionLoadingStep.PreConnect);
        }
      } catch (error) {
        console.error(error);
        setConnectStep(SessionLoadingStep.Timeout);
      }
    })();

    return () => {

    };
  }, []);

  return {
    connectionStep, userAlias
  };
}

export function useCreateSessionIfNotConnect(
  sessionKey: string
) {
  const [userAlias, setUserAlias] = useState("");
  const [connectionStep, setConnectStep] = useState(SessionLoadingStep.Loading);

  useEffect(() => {
    const createSessionAsync = async () => {
      const alias = RandomAliasHelper.create();
      retryAsync(async () => {
        const sessionController = new SessionController();
        await sessionController.postConnectUser({
          sessionKey: sessionKey,
          userAlias: alias
        });
        setUserAlias(alias);
        setConnectStep(SessionLoadingStep.Connect);
      }, 2);

      // todo: 예외처리 필요
    }
    const getSessionAsync = async () => {
      try {
        let sessionController = new SessionController();
        const result = await sessionController.getFromKeyAsync(sessionKey);

        if (result.status.connect) {
          setTimeout(() => {
            result.data &&
              result.data.user_alias &&
              setUserAlias(result.data.user_alias);
            setConnectStep(SessionLoadingStep.Connect);
          }, 1500)
        } else if (result.status.timeout) {
          setConnectStep(SessionLoadingStep.Timeout);
        } else {
          setConnectStep(SessionLoadingStep.PreConnect);
        }
      } catch (error) {
        console.error(error);
        setConnectStep(SessionLoadingStep.Timeout);
      }
    };

    // 제어
    switch (connectionStep) {
      case SessionLoadingStep.Loading:
        getSessionAsync();
        break;
      case SessionLoadingStep.PreConnect:
        createSessionAsync();
        break;
      case SessionLoadingStep.Connect:
      case SessionLoadingStep.Timeout:
        break;
    }
  }, [connectionStep])

  return { connectionStep, userAlias }
}

export enum SessionUploadStep {
  Ready,
  Uploading,
  Complete,
  Fail = -1
}

export function useUploadImages(sessionKey: string) {
  const [uploadStep, setUploadStep] = useState(SessionUploadStep.Ready);
  const [percent, setPercent] = useState(0);

  const uploadFileListAsync = async (fileList: FileList) => {
    const sessionController = new SessionController();

    setUploadStep(SessionUploadStep.Uploading);
    setTimeout(async () => {
      try {
        const length = fileList.length;

        for (let idx = 0; idx < length; idx++) {
          const compressedBlob = await compressorBlobAsync(fileList[idx]);
          await sessionController.postImagesAsync(
            sessionKey, compressedBlob, (percent) => {
              setPercent(((idx / length) + (percent / 100) / length) * 100);
            });
          setPercent((idx + 1) / length * 100);
        }
        setUploadStep(SessionUploadStep.Complete);
        setTimeout(() => {
          setUploadStep(SessionUploadStep.Ready);
          setPercent(0);
        }, 5000);
      } catch (error) {
        setUploadStep(SessionUploadStep.Fail);
        console.log(error);
      }
    }, 1);
  }

  return { uploadStep, percent, uploadFileListAsync }
}