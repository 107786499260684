import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './CssReset.css';
import './App.scss';
import ConnectPage from './pages/ConnectPage';
import MyPlacePage from './pages/MyPlacePage';
import ExitPage from './pages/ExitPage';
import TimeoutPage from './pages/TimeoutPage';
import MainPage from './pages/MainPage';
import SessionPage from './pages/SessionPage';

function App() {
  return (
    <Router>
      <Switch>
        <Route path={"/connect/:key"} component={ConnectPage} />
        <Route path={"/session/:key"} component={SessionPage} />
        <Route path={"/myplace"} component={MyPlacePage} />
        <Route path={"/exit"} component={ExitPage} />
        <Route path={"/timeout"} component={TimeoutPage} />
        <Route path={"/"} component={MainPage} />
        <Route path={"/**"} component={MainPage} />
      </Switch>
    </Router>
  );
}

export default App;
