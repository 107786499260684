import * as React from 'react';
import style from './Header.module.scss';


export interface HeaderProps {
  
}

const Header: React.FC<HeaderProps> = ({}) => {
  return <header className={style.header}>
    <div className={style.title}><span>폴라폴라</span> <span className={style.second}>스티커 제작소</span></div>
  </header>
}
export default Header;