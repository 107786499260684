import * as React from 'react';

export interface IMainPageProps {
}

export default function MainPage (props: IMainPageProps) {
  return (
    <div>
      main page
    </div>
  );
}
